import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from './actions'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * All of the properties in the interface should be annotated `readonly`, as should
 * all of the properties down the tree.
 */
export interface StoreState {
	readonly uid?: string
	readonly displayName?: string
	readonly joinSessionError?: Error
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	/* Note that we end each property with a comma, so we can add new properties without modifying this line
	(improve your git diffs!).
	*/
	displayName: undefined,
	joinSessionError: undefined,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState<StoreState>(INITIAL_STATE)
	/* Reducer function for the exampleAction that returns a new state using an implicit return. */
	.case(actions.signIn.done, (state, payload): StoreState => ({
		...state,
		displayName: payload.params.displayName,
		uid: payload.result.uid,
	}))
	.case(actions.signIn.failed, (state, payload): StoreState => ({
		...state,
		joinSessionError: payload.error,
	}))
	.case(actions.clearJoinSessionError, (state): StoreState => ({
		...state,
		joinSessionError: undefined,
	}))