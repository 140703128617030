import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { JoinCodeParams, NewGameParams, SignInParams, SignInResult } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Auth')

export type SignInAction = Action<SignInParams>
export type SignInSuccessAction = Action<Success<SignInParams, SignInResult>>
export const signIn = actionCreator.async<SignInParams, SignInResult, Error>('SIGN_IN')

export type NavigateToNewGameAction = Action<NewGameParams>
export const navigateToNewGame = actionCreator<NewGameParams>('NAVIGATE_TO_NEW_GAME')
export const navigateToGame = actionCreator('NAVIGATE_TO_GAME')

export type NavigateToJoinAction = Action<JoinCodeParams>
export const navigateToJoin = actionCreator<JoinCodeParams>('NAVIGATE_TO_JOIN')

export const clearJoinSessionError = actionCreator('CLEAR_SESSION_ERROR')
export const navigateToHome = actionCreator('NAVIGATE_TO_HOME')
