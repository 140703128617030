import { DeckMetadata } from './types'

export const PENALTY_DELAY_IN_MILLIS = 2500

export const DEFAULT_TABLE_CARD_ID = '-1'

export const ROUND_COUNT_DEFAULT = 3

export const STORAGE_BUCKET = 'iris-p01.appspot.com'

// constants for a deck
export const DOC_DECK_N = 'n'
export const DOC_DECK_CARDS_COUNT = 'deckNumberOfCardsAndSymbols'
export const DOC_DECK_CARD_SYMBOL_COUNT = 'deckCardSymbolCount'
export const DOC_DECK_FOLDER = 'deckFolder'

// constants for the sessions
export const COL_SESSIONS = 'sessions'
export const DOC_SESSION_GAME_STATE = 'gameState'
export const DOC_SESSION_OWNER_NAME = 'ownerName'
export const DOC_SESSION_OWNER_UID = 'ownerUid'
export const DOC_SESSION_SYMBOLS = 'cardSymbols'
export const DOC_SESSION_CARD_ID = 'cardId'
export const DOC_SESSION_ROUND_REMAINING_USERS = 'roundRemainingUsers'
export const DOC_SESSION_ROUND_COUNT = 'roundCount'
export const DOC_SESSION_CURRENT_ROUND = 'currentRound'
export const DOC_SESSION_GAME_MODE = 'mode'
export const DOC_SESSION_START_TIMESTAMP = 'startTimestamp'
export const DOC_SESSION_END_TIMESTAMP = 'endTimestamp'
// current deck
export const DOC_SESSION_DECK_FOLDER = DOC_DECK_FOLDER
export const DOC_SESSION_N = DOC_DECK_N

// constants for the session users
export const COL_SESSION_USERS = 'users'
export const COL_USER_CARDS = 'cards'
export const DOC_USER_CARDS_IN_HAND = 'cardsInHand'
export const DOC_USER_NAME = 'name'
export const DOC_USER_AVATAR = 'avatar'
export const DOC_USER_UID = 'uid'
export const DOC_USER_PENALTY_START_TIMESTAMP = 'penaltyStartTimestamp'
export const DOC_USER_PENALTY_END_TIMESTAMP = 'penaltyEndTimestamp'
export const DOC_USER_PENALTY_CURRENT = 'penaltyIsCurrent'
export const DOC_USER_SCORE_LAST_ROUND = 'scoreLastRound'
export const DOC_USER_SCORE_TOTAL = 'scoreTotal'
export const DOC_USER_STREAK_POINTS = 'streakPoints'

// constants for the cards
export const DOC_CARD_ID = 'id'
export const DOC_CARD_ORDERING = 'ordering'
export const DOC_CARD_SYMBOLS = 'cardSymbols'

// constants for the symbols
export const COL_SYMBOLS = 'cardSymbols'
export const DOC_SYMBOL_ID = 'id'
export const DOC_SYMBOL_ROTATION = 'rotationInDegrees'
export const DOC_SYMBOL_SCALE = 'scale'
export const DOC_SYMBOL_IMAGE_URL = 'imageUrl'


// deck sizes
// n is prime:
// const numberOfCards / total symbols = n * n + n + 1
// const symbolsOnCard = n + 1
// n, cards, symbols on card
// 2, 7, 3
// 3, 13, 4
// 5, 31, 6
// 7, 57, 8
// 11, 133, 12
export const DECK_MATRIX: DeckMetadata[] = [
	{
		[DOC_DECK_N]: 2,
		[DOC_DECK_CARDS_COUNT]: 7,
		[DOC_DECK_CARD_SYMBOL_COUNT]: 3,
	},
	{
		[DOC_DECK_N]: 3,
		[DOC_DECK_CARDS_COUNT]: 13,
		[DOC_DECK_CARD_SYMBOL_COUNT]: 4,
	},
	{
		[DOC_DECK_N]: 5,
		[DOC_DECK_CARDS_COUNT]: 31,
		[DOC_DECK_CARD_SYMBOL_COUNT]: 6,
	},
	{
		[DOC_DECK_N]: 7,
		[DOC_DECK_CARDS_COUNT]: 57,
		[DOC_DECK_CARD_SYMBOL_COUNT]: 8,
	},
	{
		[DOC_DECK_N]: 11,
		[DOC_DECK_CARDS_COUNT]: 133,
		[DOC_DECK_CARD_SYMBOL_COUNT]: 12,
	},
]
