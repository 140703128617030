import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import colorProvider from '../utils/colorProvider'

const theme = createTheme({
	palette: {
		primary: {
			light: colorProvider.prelude,
			main: colorProvider.ceSoir,
			dark: colorProvider.meeseeksBlue,
			contrastText: colorProvider.white,
		},
		secondary: {
			light: colorProvider.cornField,
			main: colorProvider.lemon,
			dark: colorProvider.lucky,
			contrastText: colorProvider.white,
		},
		error: {
			light: colorProvider.bittersweet,
			main: colorProvider.red,
			dark: colorProvider.darkRed,
			contrastText: colorProvider.white,
		},
		divider: colorProvider.ceSoir,
	},
	typography: () => ({
		fontFamily: '"McLaren", sans-serif',
		button: {
			textTransform: 'none',
			fontSize: 42,
		},
	}),
	components: {
		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
				input: {
					background: colorProvider.white,
					textAlign: 'center',
					paddingTop: 25,
					paddingBottom: 25,
					fontSize: 42,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 20,
					width: 340,
					maxHeight: 100,
					padding: 20,
					color: colorProvider.haiti,
					backgroundColor: colorProvider.turquoise,
					'&.Mui-disabled': {
						backgroundColor: colorProvider.turquoise,
					},
				},
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 50,
					borderColor: colorProvider.ceSoir,
					borderWidth: 3,
					'&.MuiPaper-outlined-secondary': {
						borderColor: colorProvider.veryLightGrey,
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderWidth: 2,
					width: '100%',
				},
			},
		},
	},
})

export default responsiveFontSizes(theme)