import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

const app = initializeApp({
	projectId: 'iris-p01',
	apiKey: 'AIzaSyBgv7zkbGrSjhu3MLO7Hqd12o70b657_5c',
	authDomain: '### FIREBASE AUTH DOMAIN ###',
})
export const firebaseFunctions = getFunctions(app, 'australia-southeast1')
export const firebaseAuth = getAuth()
export const firebaseFirestore = getFirestore()

// local server
const serverEnvironment = process.env.REACT_APP_SERVER_ENVIRONMENT
if (serverEnvironment === 'LOCAL') {
	connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
	connectAuthEmulator(firebaseAuth, 'http://localhost:' + 9099)
	connectFirestoreEmulator(firebaseFirestore, 'localhost', 8082)
}
