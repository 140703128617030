import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { IrisCard, GameState, IrisUser, GetDecksResponse, SetDeckResponse, SelectDeckRequest, SetSymbolsPerCardRequest, SetSymbolsPerCardResponse } from '../api/types'
import { SelectCardSymbolParams, SelectCardSymbolResult, StartRoundParams, StartRoundResult, UpdateSessionParams } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Game')

export type StartRoundAction = Action<StartRoundParams>
export type StartRoundSuccessAction = Action<Success<StartRoundParams, StartRoundResult>>
export const startRound = actionCreator.async<StartRoundParams, StartRoundResult, Error>('START_ROUND')

export type GetDecksAction = Action<void>
export const getDecks = actionCreator.async<void, GetDecksResponse, Error>('GET_DECKS')

export type SelectDeckAction = Action<SelectDeckRequest>
export const selectDeck = actionCreator.async<SelectDeckRequest, SetDeckResponse, Error>('SELECT_DECK')

export type SetSymbolsPerCardAction = Action<SetSymbolsPerCardRequest>
export const setSymbolsPerCard = actionCreator.async<SetSymbolsPerCardRequest, SetSymbolsPerCardResponse, Error>('SET_SYMBOLS_PER_CARD')

export type SelectCardSymbolAction = Action<SelectCardSymbolParams>
export const selectCardSymbol = actionCreator.async<SelectCardSymbolParams, SelectCardSymbolResult, Error>('SELECT_CARD_SYMBOL')

export interface GameStateChangedPayload {
	gameState: GameState
	startTimestamp?: number
	endTimestamp?: number
}
export type ChangeGameStateAction = Action<GameStateChangedPayload>
export const changeGameState = actionCreator<GameStateChangedPayload>('GAME_STATE_CHANGED')

export const usersUpdated = actionCreator<IrisUser[]>('USERS_UPDATED')

export const userCardUpdated = actionCreator<IrisCard>('USER_CARD_UPDATED')

export interface RoundUpdatedPayload {
	tableCard: IrisCard
	playersLeft: number
	currentRound: number
}
export const roundUpdated = actionCreator<RoundUpdatedPayload>('ROUND_UPDATED')

export interface DeckUpdatedPayload {
	deckFolder: string
}
export const deckUpdated = actionCreator<DeckUpdatedPayload>('DECK_UPDATED')

export const symbolsPerCardUpdated = actionCreator<number>('SYMBOLS_PER_CARD_UPDATED')

export type RejoinSessionAction = Action<string>
export const rejoinSession = actionCreator<string>('REJOIN_SESSION')

export type UpdateSessionAction = Action<UpdateSessionParams>
export const updateSession = actionCreator<UpdateSessionParams>('UPDATE_SESSION')

export type LeaveGameAction = Action<string | undefined>
export const leaveGame = actionCreator.async<string | undefined, void, Error>('LEAVE_GAME')
