import './styles/App.scss'

import { Provider } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './modules/root'
import history from './modules/navigation/NavigationManager'
import { Paths } from './modules/navigation/types'
import Routes from './modules/navigation/routes'
import LazySwitch from './modules/navigation/components/LazySwitch'
import theme from './styles/muiTheme'
import GameRoute from './modules/navigation/components/GameRoute'

const App = (): JSX.Element => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<Router history={history}>
					<ThemeProvider theme={theme}>
						<div className="app">
							<main className="app__content">
								<LazySwitch>
									<Route exact={true} path={[Paths.HOME, Paths.JOIN_CODE]} component={Routes.Home} />
									<Route exact={true} path={Paths.NEW} component={Routes.GameEntry} />
									<GameRoute exact={true} path={Paths.GAME} component={Routes.Game} />
									<Route exact={true} path={Paths.TEMPLATE} component={Routes.Template} />
								</LazySwitch>
							</main>
						</div>
					</ThemeProvider>
				</Router>
			</PersistGate>
		</Provider>
	)
}

export default App
