import { reducerWithInitialState } from 'typescript-fsa-reducers'

import * as actions from './actions'
import { AvatarType } from './types'

/**
 * State
 */
export interface StoreState {
	readonly avatar: AvatarType
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	avatar: AvatarType.Avatar1,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.updateAvatar, (state, payload) => ({
		...state,
		avatar: payload,
	}))