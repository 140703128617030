import { reducerWithInitialState } from 'typescript-fsa-reducers'

import * as actions from './actions'
import { IrisDeck, IrisCard, GameState, IrisUser, GameMode } from '../api/types'
import { signIn } from '../auth/actions'

/**
 * State
 */
export interface StoreState {
	readonly sessionCode?: string

	readonly sessionOwnerUid?: string
	readonly gameMode?: GameMode

	readonly gameState?: GameState
	readonly currentRound: number
	readonly startTimestamp?: number
	readonly endTimestamp?: number

	readonly decks: IrisDeck[]
	readonly deckFolder?: string
	readonly symbolsPerCard?: number

	readonly users: IrisUser[]

	readonly currentCard?: IrisCard
	readonly tableCard?: IrisCard
	readonly playersLeft: number

	// requests loading flags
	readonly changingGameOptions: boolean
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	sessionCode: undefined,

	sessionOwnerUid: undefined,
	gameMode: undefined,

	gameState: undefined,
	currentRound: 1,
	startTimestamp: undefined,
	endTimestamp: undefined,

	users: [],
	decks: [],
	deckFolder: undefined,
	symbolsPerCard: undefined,

	currentCard: undefined,
	tableCard: undefined,
	playersLeft: 0,

	changingGameOptions: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(signIn.done, (state, payload): StoreState => ({
		...state,
		sessionCode: payload.result.sessionCode,
	}))

	.case(actions.startRound.started, (state): StoreState => ({
		...state,
		gameState: GameState.STARTING,
	}))

	.case(actions.rejoinSession, (state, payload): StoreState => ({
		...state,
		sessionCode: payload,
	}))

	.case(actions.changeGameState, (state, payload) => ({
		...state,
		gameState: payload.gameState,
		startTimestamp: payload.startTimestamp,
		endTimestamp: payload.endTimestamp,
	}))

	.case(actions.usersUpdated, (state, payload) => ({
		...state,
		users: payload,
	}))

	.case(actions.getDecks.done, (state, payload) => ({
		...state,
		decks: payload.result.decks,
	}))

	.case(actions.userCardUpdated, (state, payload) => ({
		...state,
		currentCard: payload,
	}))

	.case(actions.roundUpdated, (state, payload) => ({
		...state,
		tableCard: payload.tableCard,
		playersLeft: payload.playersLeft,
		currentRound: payload.currentRound,
	}))

	.case(actions.deckUpdated, (state, payload) => ({
		...state,
		deckFolder: payload.deckFolder,
	}))

	.case(actions.updateSession, (state, payload) => ({
		...state,
		sessionOwnerUid: payload.ownerUid,
		gameMode: payload.gameMode,
	}))

	.case(actions.symbolsPerCardUpdated, (state, payload) => ({
		...state,
		symbolsPerCard: payload,
	}))

	.cases([actions.setSymbolsPerCard.started, actions.selectDeck.started], (state) => ({
		...state,
		changingGameOptions: true,
	}))
	.cases([actions.setSymbolsPerCard.done, actions.setSymbolsPerCard.failed, actions.selectDeck.done, actions.selectDeck.failed], (state) => ({
		...state,
		changingGameOptions: false,
	}))

	.cases([actions.leaveGame.done, actions.leaveGame.failed], () => INITIAL_STATE)
