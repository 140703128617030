import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import * as actions from '../template/actions'
import * as RootNavigation from '../navigation/NavigationManager'

function handleExampleAction(action: Action<string>) {
	console.log('saga handleExampleAction:', action.payload)
}

function handleNavigateToHome() {
	RootNavigation.navigateToHome()
}

function handleNavigateToTemplate(action: actions.NavigateToTemplateAction) {
	const params = action.payload || undefined
	RootNavigation.navigateToTemplate(params)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.examplePrimitiveAction, handleExampleAction)
	yield takeEvery(actions.navigateToHome, handleNavigateToHome)
	yield takeEvery(actions.navigateToTemplate, handleNavigateToTemplate)
}
