import { SagaIterator } from 'redux-saga'
import { select, takeEvery } from 'redux-saga/effects'
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { getAuth } from 'firebase/auth'

import * as actions from '../game/actions'
import { firebaseFunctions, firebaseFirestore } from '../firebase'
import { RootStoreState, store } from '../root'
import { signIn, SignInSuccessAction } from '../auth/actions'
import { SelectCardSymbolResult } from './types'
import { getSessionDocumentPath, getUserCardsCollectionPath, getUsersCollectionPath } from '../api/functions'
import { GameState, GetDecksResponse, IrisCard, IrisSession, IrisUser, SelectDeckRequest, SetDeckResponse, UserLeaveRequest, SetSymbolsPerCardRequest, SetSymbolsPerCardResponse } from '../api/types'
import { createCollection } from '../../utils/functions'
import * as RootNavigation from '../navigation/NavigationManager'
import { DEFAULT_TABLE_CARD_ID } from '../api/constants'
// import { StartRoundResult } from './types'

const startRound = httpsCallable(firebaseFunctions, 'startRound')
const selectCardSymbol = httpsCallable(firebaseFunctions, 'selectCardSymbol')
const getDecks = httpsCallable<undefined, GetDecksResponse>(firebaseFunctions, 'getDecks')
const setDeck = httpsCallable<SelectDeckRequest, SetDeckResponse>(firebaseFunctions, 'setDeck')
const setSymbolsPerCard = httpsCallable<SetSymbolsPerCardRequest, SetSymbolsPerCardResponse>(firebaseFunctions, 'setSymbolsPerCard')
const userLeave = httpsCallable<UserLeaveRequest, void>(firebaseFunctions, 'userLeave')

function handleSelectCardSymbol(action: actions.SelectCardSymbolAction) {
	selectCardSymbol({
		sessionCode: action.payload.sessionCode,
		sessionCardId: action.payload.tableCardId,
		cardId: action.payload.cardId,
		symbolId: action.payload.symbolId,
	})
		.then((result) => {
			// Read result of the Cloud Function.
			const data = result.data as SelectCardSymbolResult
			store.dispatch(actions.selectCardSymbol.done({
				params: action.payload, result: {
					sessionCode: data.sessionCode
				}
			}))
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			console.log(errorCode, errorMessage)
			// ...
			store.dispatch(actions.selectCardSymbol.failed({ params: action.payload, error }))
		})
}

function handleStartRound(action: actions.StartRoundAction) {
	const params = action.payload || undefined
	startRound({ sessionCode: action.payload.sessionCode })
		.then(() => {
			// Read result of the Cloud Function.
			// const data = result.data as StartRoundResult
			store.dispatch(actions.startRound.done({
				params: action.payload, result: {
					sessionCode: params.sessionCode,
				}
			}))
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			console.log(errorCode, errorMessage)
			// ...
			store.dispatch(actions.startRound.failed({ params: action.payload, error }))
		})
}

let unsubscribeUserCardSession: Unsubscribe | undefined

function* handleGameStateChanged(action: actions.ChangeGameStateAction): SagaIterator {
	if (action.payload.gameState >= GameState.IN_PROGRESS) {
		const userId = yield select((state: RootStoreState): string | undefined => state.auth.uid)
		const sessionCode = yield select((state: RootStoreState): string | undefined => state.game.sessionCode)
		console.log('handleGameStateChanged:', userId, sessionCode)
		if (userId && sessionCode) {
			console.log('fetchUserCards:')
			if (unsubscribeUserCardSession) unsubscribeUserCardSession()
			unsubscribeUserCardSession = onSnapshot(createCollection<IrisCard>(getUserCardsCollectionPath(sessionCode, userId)), (doc) => {
				const cards: IrisCard[] = doc.docs.map(card => card.data())

				// get current card based on the ordering 
				const currentCard = cards.reduce<IrisCard | undefined>((minCard, item) => {
					if (!minCard || (item.ordering !== undefined && minCard.ordering !== undefined && item.ordering < minCard.ordering)) {
						return item
					}
					return minCard
				}, undefined)

				if (currentCard) store.dispatch(actions.userCardUpdated(currentCard))
			})
		}
	}
}

let unsubscribeSession: Unsubscribe | undefined
let unsubscribeSessionUsers: Unsubscribe | undefined

function handleSessionJoined(action: SignInSuccessAction) {
	const sessionCode = action.payload.result.sessionCode

	startListeningToGame(sessionCode)
}

function startListeningToGame(sessionCode: string) {
	console.log('start listening to game:', sessionCode)

	// listen to parent session object
	if (unsubscribeSession) unsubscribeSession()
	unsubscribeSession = onSnapshot(doc(firebaseFirestore, getSessionDocumentPath(sessionCode)), (doc) => {
		const storeState = store.getState()
		const session = doc.data() as IrisSession
		console.log('Session updated: ', session)

		// 1 - store the game state
		if (storeState.game.gameState !== session.gameState) {
			store.dispatch(actions.changeGameState({ gameState: session.gameState, startTimestamp: session.startTimestamp, endTimestamp: session.endTimestamp }))
		}

		// 2 - store the table card
		const tableCard: IrisCard = {
			id: session.cardId || DEFAULT_TABLE_CARD_ID,
			cardSymbols: session.cardSymbols || [],
		}
		store.dispatch(actions.roundUpdated({ tableCard, playersLeft: session.roundRemainingUsers, currentRound: session.currentRound }))


		// 3 - store the session data
		if (storeState.game.sessionOwnerUid === undefined) {
			store.dispatch(actions.updateSession({
				ownerUid: session.ownerUid,
				gameMode: session.mode,
			}))
		}

		// 4 - store the deck folder
		if (storeState.game.deckFolder !== session.deckFolder) {
			store.dispatch(actions.deckUpdated({
				deckFolder: session.deckFolder,
			}))
		}

		// 5 - store the symbols per card
		if (storeState.game.symbolsPerCard !== session.n + 1) {
			// n+1 when displaying symbols per card
			const symbols = session.n + 1
			store.dispatch(actions.symbolsPerCardUpdated(symbols))
		}
	})

	// listen to users collection on a single session
	if (unsubscribeSessionUsers) unsubscribeSessionUsers()
	unsubscribeSessionUsers = onSnapshot(createCollection<IrisUser>(getUsersCollectionPath(sessionCode)), (doc) => {
		console.log('Session users updated', doc.size)
		const users: IrisUser[] = doc.docs.map(user => user.data())
		store.dispatch(actions.usersUpdated(users))
	})

	// load decks
	handleGetDecks()
}

function handleGetDecks() {
	// when joining a game (signing in) get all the decks so the game owner can pick a deck for the session, and all users can predownload the images before starting the game
	getDecks()
		.then((result) => {
			const decks = result.data
			store.dispatch(actions.getDecks.done({ result: decks }))
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			console.log(errorCode, errorMessage)
			store.dispatch(actions.getDecks.failed({ error }))
		})
}

// Sets the deck for the current session
function handleSetDeck(action: actions.SelectDeckAction) {
	setDeck(action.payload)
		.then((result) => {
			const deck = result.data
			store.dispatch(actions.selectDeck.done({ params: action.payload, result: deck }))
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			console.log(errorCode, errorMessage)
			store.dispatch(actions.selectDeck.failed({ params: action.payload, error }))
		})
}

// Sets the symbols per card for the current session
function handleSetSymbolsPerCard(action: actions.SetSymbolsPerCardAction) {
	setSymbolsPerCard(action.payload)
		.then((result) => {
			const data = result.data
			store.dispatch(actions.setSymbolsPerCard.done({ params: action.payload, result: data }))
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			console.log(errorCode, errorMessage)
			store.dispatch(actions.setSymbolsPerCard.failed({ params: action.payload, error }))
		})
}

function handleRejoinSession(action: actions.RejoinSessionAction) {
	// if there is already a subscription for game state setup, do nothing
	if (unsubscribeSession) {
		console.log('game subscription already exists, not rejoining session')
		return
	}
	// check user is signed in
	// Note: we cannot use `getAuth().currentUser` because it is not populated until about 2 seconds after page loads (after refreshing)
	const unsubscribe = getAuth().onAuthStateChanged(user => {
		console.log('onAuthStateChanged', user)
		// check if we have a logged in user
		if (user) {
			const sessionCode = action.payload
			startListeningToGame(sessionCode)
		} else {
			// navigate user to Home screen
			RootNavigation.navigateToHome()
		}
		// unsubscribe to auth changes, because we just do this as one off call to get user state
		unsubscribe()
	})
}

function handleLeaveGame(action: actions.LeaveGameAction) {
	// leave the game on server (remove user)
	console.log('remove user from game on server')
	const sessionCode = action.payload
	if (sessionCode) {
		userLeave({ sessionCode })
			.then(() => {
				// sign user out of firebase (so they cannot go back to previous game url and start listening to session again)
				getAuth().signOut()

				// unregister any firebase listeners
				if (unsubscribeSessionUsers) unsubscribeSessionUsers()
				if (unsubscribeSession) unsubscribeSession()
				if (unsubscribeUserCardSession) unsubscribeUserCardSession()

				// dispatch event to clear redux state
				store.dispatch(actions.leaveGame.done({ params: sessionCode }))

				// navigate home
				RootNavigation.navigateToHome()
			})
			.catch((error) => {
				const errorCode = error.code
				const errorMessage = error.message
				console.log(errorCode, errorMessage)
				// dispatch failed event to still clear redux state
				store.dispatch(actions.leaveGame.failed({ error, params: sessionCode }))
			})
	}
}

export default function* (): SagaIterator {
	yield takeEvery(actions.startRound.started, handleStartRound)
	yield takeEvery(actions.changeGameState, handleGameStateChanged)
	yield takeEvery(signIn.done, handleSessionJoined)
	yield takeEvery(actions.selectDeck.started, handleSetDeck)
	yield takeEvery(actions.setSymbolsPerCard.started, handleSetSymbolsPerCard)
	yield takeEvery(actions.selectCardSymbol.started, handleSelectCardSymbol)
	yield takeEvery(actions.rejoinSession, handleRejoinSession)
	yield takeEvery(actions.leaveGame.started, handleLeaveGame)
}
