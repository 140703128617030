import { createBrowserHistory, LocationDescriptor } from 'history'

import { Paths } from './types'
import { TemplateNavigationParams } from '../template/types'


/**
 * Get access to the root navigation object.
 */
const history = createBrowserHistory({})
export default history

/* -------------------------------------------------------------------------- */
/*                              Screen Navigation                             */
/* -------------------------------------------------------------------------- */

export const navigateToHome = (): void => {
	history.push(Paths.HOME)
}

export const navigateToTemplate = (params?: TemplateNavigationParams): void => {
	const location: LocationDescriptor<TemplateNavigationParams> = {
		pathname: Paths.TEMPLATE,
		state: params,
	}
	history.push(location)
}

export const navigateToGame = (code: string): void => {
	history.push(`/${code}`)
}

export const navigateToNewGame = (name: string): void => {
	history.push(`/new/${name}`)
}

export const navigateToJoin = (code: string): void => {
	history.push(`/join/${code}`)
}
