import { Route, RouteProps, Redirect, RouteComponentProps } from 'react-router'

import { GameNavigationProps, Paths } from '../types'

const GameRoute = ({ component: Component, render, children, ...rest }: RouteProps): JSX.Element => (
	<Route
		{...rest}
		render={(props: RouteComponentProps<GameNavigationProps>) => {
			if (props.match.params.code) {
				if (Component) {
					return <Component {...props} />
				} else if (render) {
					return render(props)
				} else if (children) {
					return children
				}
				return null
			}
			return (
				<Redirect
					to={{
						pathname: Paths.HOME,
					}}
				/>
			)
		}}
	/>
)


export default GameRoute
