import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'
import { httpsCallable } from 'firebase/functions'
import { signInAnonymously } from 'firebase/auth'

import * as actions from '../auth/actions'
import { firebaseAuth, firebaseFunctions } from '../firebase'
import { store } from '../root'
import { JoinSessionRequest, JoinSessionResponse } from '../api/types'
import * as RootNavigation from '../navigation/NavigationManager'

const joinSession = httpsCallable<JoinSessionRequest, JoinSessionResponse>(firebaseFunctions, 'joinSession')

function handleSignIn(action: actions.SignInAction) {
	// const params = action.payload || undefined
	signInAnonymously(firebaseAuth)
		.then(() => {
			// Signed in...
			console.log('Signed in')

			// Set the display name on the user
			const sessionCode = action.payload.sessionCode
			let payload: JoinSessionRequest = { displayName: action.payload.displayName, gameMode: action.payload.gameMode, avatar: action.payload.avatar }
			if (sessionCode) payload = { ...payload, sessionCode }
			joinSession(payload)
				.then((result) => {
					// Read result of the Cloud Function.
					const data = result.data
					store.dispatch(actions.signIn.done({
						params: action.payload, result: {
							uid: firebaseAuth.currentUser!.uid,
							sessionCode: data.code,
						}
					}))
				})
				.catch((error) => {
					const errorCode = error.code
					const errorMessage = error.message
					console.log(errorCode, errorMessage)
					// ...
					store.dispatch(actions.signIn.failed({ params: action.payload, error }))
				})
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			console.log(errorCode, errorMessage)
			// ...
			store.dispatch(actions.signIn.failed({ params: action.payload, error }))
		})
}

function handleSignInSuccess(action: actions.SignInSuccessAction) {
	RootNavigation.navigateToGame(action.payload.result.sessionCode)
}

function handleNavigateToNewGame(action: actions.NavigateToNewGameAction) {
	RootNavigation.navigateToNewGame(action.payload.displayName)
}

function handleNavigateToJoin(action: actions.NavigateToJoinAction) {
	RootNavigation.navigateToJoin(action.payload.sessionCode)
}

function handleNavigateToHome() {
	RootNavigation.navigateToHome()
}

export default function* (): SagaIterator {
	yield takeEvery(actions.signIn.started, handleSignIn)
	yield takeEvery(actions.signIn.done, handleSignInSuccess)
	yield takeEvery(actions.navigateToNewGame, handleNavigateToNewGame)
	yield takeEvery(actions.navigateToJoin, handleNavigateToJoin)
	yield takeEvery(actions.navigateToHome, handleNavigateToHome)
}
