import React, { Suspense } from 'react'
import { Switch, SwitchProps } from 'react-router-dom'

import Loader from './Loader'
import LoaderErrorBoundary from './LoaderErrorBoundary'

/**
 * A wrapped react-router `Switch` to handle route-based splitting.
 * 
 * @param props component props
 */
const LazySwitch = ({ children, location }: SwitchProps): JSX.Element => (
	<LoaderErrorBoundary>
		<Suspense fallback={<Loader />}>
			<Switch location={location}>
				{children}
			</Switch>
		</Suspense>
	</LoaderErrorBoundary>
)

export default LazySwitch
