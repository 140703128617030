import { COL_SESSIONS, COL_SESSION_USERS, COL_USER_CARDS } from './constants'

/** Document and collection paths */
export function getSessionsCollectionPath(): string {
	return COL_SESSIONS
}

export function getSessionDocumentPath(sessionId: string): string {
	return getSessionsCollectionPath() + '/' + sessionId
}

export function getUsersCollectionPath(sessionId: string): string {
	return getSessionDocumentPath(sessionId) + '/' + COL_SESSION_USERS
}

export function getUserDocumentPath(sessionId: string, uid: string): string {
	return getUsersCollectionPath(sessionId) + '/' + uid
}

export function getUserCardsCollectionPath(sessionId: string, uid: string): string {
	return getUserDocumentPath(sessionId, uid) + '/' + COL_USER_CARDS
}

export function getUserCardDocumentPath(
	sessionId: string,
	uid: string,
	cardId: string): string {
	return getUserCardsCollectionPath(sessionId, uid) + '/' + cardId
}
