/**
 * Root template for configuring the redux store with redux-persist.
 * 
 * Optional - to be configured on projects where data is to be persisted.
 */

import { createStore, combineReducers, compose, applyMiddleware, Middleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import loggerMiddleware from '../../utils/logger'
import rootSaga from './sagas'

/* Import reducers from our modules */
import * as template from '../template/reducer'
import * as auth from '../auth/reducer'
import * as game from '../game/reducer'
import * as avatar from '../avatar/reducer'

/**
 * The module store state. Include sub-states for all of the modules / ducks.
 * All of these should be annotated `readonly`, as should everything down
 * the tree of StoreState interfaces, and their contents.
 */
export interface RootStoreState {
	readonly template: template.StoreState
	readonly auth: auth.StoreState
	readonly game: game.StoreState
	readonly avatar: avatar.StoreState
}

/**
 * The root reducer, combines reducers for all of the modules / ducks.
 */
const reducer = combineReducers<RootStoreState>({
	template: template.reducer,
	auth: auth.reducer,
	game: game.reducer,
	avatar: avatar.reducer,
})

const persistConfig: PersistConfig<RootStoreState> = {
	key: 'root',
	storage, // the storage adapter
	whitelist: ['auth'],
}

/**
 * Combines reducers for all of the modules / ducks alongside the persistence configuration.
 */
const persistedReducer = persistReducer<RootStoreState>(persistConfig, reducer)

/**
 * Create the redux-saga middleware - which run constantly and listens for redux actions
 */
const sagaMiddleware = createSagaMiddleware()
const middlewares: Middleware[] = [sagaMiddleware, loggerMiddleware]

/**
 * Enhancers for the store.
 */
const enhancers = compose(
	/* Add the middlewares */
	applyMiddleware(...middlewares),
)

/**
 * Create the store. We do not include an initial state, as each of the module / duck
 * reducers includes its own initial state.
 */
export const store = createStore(persistedReducer, enhancers)

/**
 * Creates a persistor for a given store.
 */
export const persistor = persistStore(store)

/* Run the root saga */
sagaMiddleware.run(rootSaga)
