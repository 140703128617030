import { COL_SESSION_USERS, DOC_CARD_ID, DOC_CARD_ORDERING, DOC_CARD_SYMBOLS, DOC_DECK_CARDS_COUNT, DOC_DECK_CARD_SYMBOL_COUNT, DOC_DECK_FOLDER, DOC_DECK_N, DOC_SESSION_CURRENT_ROUND, DOC_SESSION_DECK_FOLDER, DOC_SESSION_GAME_STATE, DOC_SESSION_CARD_ID, DOC_SESSION_OWNER_NAME, DOC_SESSION_OWNER_UID, DOC_SESSION_ROUND_COUNT, DOC_SESSION_ROUND_REMAINING_USERS, DOC_SESSION_SYMBOLS, DOC_SYMBOL_ID, DOC_SYMBOL_IMAGE_URL, DOC_SYMBOL_ROTATION, DOC_SYMBOL_SCALE, DOC_USER_CARDS_IN_HAND, DOC_USER_NAME, DOC_USER_PENALTY_CURRENT, DOC_USER_PENALTY_END_TIMESTAMP, DOC_USER_PENALTY_START_TIMESTAMP, DOC_USER_SCORE_LAST_ROUND, DOC_USER_SCORE_TOTAL, DOC_USER_UID, DOC_SESSION_N, DOC_SESSION_GAME_MODE, DOC_SESSION_START_TIMESTAMP, DOC_SESSION_END_TIMESTAMP, DOC_USER_AVATAR, DOC_USER_STREAK_POINTS } from './constants'

/** API types for function request and response objects */
export type JoinSessionRequest = { displayName: string, avatar: number, sessionCode?: string, gameMode?: GameMode }

export enum GameMode {
    NORMAL, SOLO
}

export type JoinSessionResponse = { code: string }

export type GetDecksResponse = { decks: IrisDeck[] }

export type SelectCardSymbolResult = { sessionCode: string }

export type UserLeaveRequest = { sessionCode: string }

export type SelectDeckRequest = { sessionCode: string, deckFolder: string }

export type SetSymbolsPerCardRequest = { sessionCode: string, symbols: number }

export type SetDeckResponse = { deck: IrisDeck }

export type SetSymbolsPerCardResponse = { symbols: number }

export type IrisDeck = {
    [DOC_DECK_FOLDER]: string,
    [DOC_DECK_CARDS_COUNT]: number,
    [DOC_DECK_N]: number,
    [DOC_DECK_CARD_SYMBOL_COUNT]: number,
    iconUrls: string[]
}

export type DeckMetadata = {
    [DOC_DECK_N]: number,
    [DOC_DECK_CARDS_COUNT]: number,
    [DOC_DECK_CARD_SYMBOL_COUNT]: number,
}

/** State */
export enum GameState {
    LOBBY, STARTING, IN_PROGRESS, ROUND_ENDING, ROUND_ENDED, GAME_ENDING, GAME_ENDED
}

/** The objects in firestore */
export interface IrisSession {
    [DOC_SESSION_OWNER_UID]: string
    [DOC_SESSION_OWNER_NAME]: string
    [DOC_SESSION_GAME_STATE]: GameState
    [COL_SESSION_USERS]?: [IrisUser]
    [DOC_SESSION_SYMBOLS]?: [IrisSymbol]
    [DOC_SESSION_CARD_ID]?: string
    [DOC_SESSION_ROUND_REMAINING_USERS]: number
    [DOC_SESSION_ROUND_COUNT]: number
    [DOC_SESSION_CURRENT_ROUND]: number
    [DOC_SESSION_START_TIMESTAMP]?: number
    [DOC_SESSION_END_TIMESTAMP]?: number
    [DOC_SESSION_DECK_FOLDER]: string
    [DOC_SESSION_N]: number
    [DOC_SESSION_GAME_MODE]: GameMode
}

export interface IrisUser {
    [DOC_USER_UID]: string
    [DOC_USER_NAME]: string
    [DOC_USER_AVATAR]: number
    [DOC_USER_CARDS_IN_HAND]: number
    [DOC_USER_PENALTY_START_TIMESTAMP]?: number
    [DOC_USER_PENALTY_END_TIMESTAMP]?: number
    [DOC_USER_PENALTY_CURRENT]?: boolean
    [DOC_USER_SCORE_LAST_ROUND]: number
    [DOC_USER_SCORE_TOTAL]: number
	[DOC_USER_STREAK_POINTS]: number
}

export interface IrisCard {
    [DOC_CARD_ID]: string
    [DOC_CARD_ORDERING]?: number
    [DOC_CARD_SYMBOLS]: IrisSymbol[]
}

export interface IrisSymbol {
    [DOC_SYMBOL_ID]: string
    [DOC_SYMBOL_ROTATION]: number
    [DOC_SYMBOL_SCALE]: number
    [DOC_SYMBOL_IMAGE_URL]: string
}

