import { all } from 'redux-saga/effects'

import templateSaga from '../template/sagas'
import authSaga from '../auth/sagas'
import gameSaga from '../game/sagas'

export default function* rootSaga(): Generator {
	yield all([
		templateSaga(),
		authSaga(),
		gameSaga(),
	])
}
