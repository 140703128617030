import actionCreatorFactory, { Action } from 'typescript-fsa'
import { TemplateNavigationParams } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Template')

/* An example action with an interface as its payload. */
export type ExampleAction = Action<ExampleActionPayload>
export interface ExampleActionPayload {
	value: string
}
export const exampleAction = actionCreator<ExampleActionPayload>('EXAMPLE')

/* An example action with a primitive payload. */
export const examplePrimitiveAction = actionCreator<string>('EXAMPLE_PRIMITIVE')

export type NavigateToTemplateAction = Action<TemplateNavigationParams | void>
export const navigateToTemplate = actionCreator<TemplateNavigationParams | void>('NAVIGATE_TO_TEMPLATE')

export const navigateToHome = actionCreator('NAVIGATE_TO_HOME')