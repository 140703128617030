/**
 * Defines the paths for all screens.
 */
export enum Paths {
	HOME = '/',
	NEW = '/new/:name',
	JOIN_CODE = '/join/:code?',
	TEMPLATE = '/template',
	GAME = '/:code',
}

export type NewGameNavigationProps = {
	name: string
}

export type GameNavigationProps = {
	code?: string
}
