import { DocumentData, collection, CollectionReference } from 'firebase/firestore'
import { IrisDeck } from '../modules/api/types'
import { firebaseFirestore } from '../modules/firebase'

export const createCollection = <T = DocumentData>(collectionName: string) => {
	return collection(firebaseFirestore, collectionName) as CollectionReference<T>
}

export const getValidSymbolsPerCardForDeck = (deck: IrisDeck): number[] => {
	switch (deck.n) {
		case 3: return [4]
		case 5: return [4, 6]
		case 7: return [4, 6, 8]
		case 11: return [4, 6, 8, 12]
		default: return []
	}
}